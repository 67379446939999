import { Component, Input, OnInit } from '@angular/core';
import { FileData } from 'src/app/models/file.model';
import { HelperService } from 'src/app/services/helper.service';
import { ModalService } from 'src/app/services/modal.service';
import { SubjectService } from 'src/app/services/subject.service';

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss']
})
export class FileListComponent {

  @Input() fileData: FileData[];
  @Input() isPartners: boolean;
  ifFilesValid: boolean = false;

  constructor(public modal: ModalService, public helper: HelperService, private subject: SubjectService) { }

  get isValidFiles() {
    if (this.isPartners) {
      if(this.fileData) {
        return this.fileData.some(file => file.file.length === 0
        )
      }
    } else {
      let arrayFile = [];
      let valid = true;
      this.fileData.forEach(file => {
        if (file.file.length > 0)  {
          arrayFile.push(true);
        };
        if ((file.file.length === 0) && (file.description === 'Cámara de comercio')) {
          arrayFile.push(true);
        }
      });
      if (arrayFile.length === 6 || arrayFile.length === 5 ) {
        return valid = false;
      } else {
        return valid  = true;
      }
    }
  }

  public closeUploadModal(): void {
    //console.log(!this.isValidFiles);
    
    this.subject.changeStateValidFiles(!this.isValidFiles);
    this.modal.close();
  }

  verifyValidUploadForm() {
    let arrayFile = [];
    this.fileData.forEach(file => {
      arrayFile.push(file.file.length > 0);
    });
  }
}
