import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(private toastr: ToastrService) { }

  onlyNumber($event) {
    const arrayCodes = [8, 9, 37, 39];
    if (
      $event.keyCode === 91 ||
      $event.keyCode === 88 ||
      $event.keyCode === 67 ||
      $event.keyCode === 86 ||
      $event.keyCode === 13
    ) {
      return;
    } else if (
      arrayCodes.indexOf($event.keyCode) === -1 &&
      $event.key.search(/\d\b/) === -1
    ) {
      $event.preventDefault();
    }
  }

  onlyNumberRate($event) {
    const arrayCodes = [8, 9, 37, 39];
    if (
      $event.keyCode === 91 ||
      $event.keyCode === 88 ||
      $event.keyCode === 67 ||
      $event.keyCode === 86 ||
      $event.keyCode === 13
    ) {
      return;
    } else if (
      arrayCodes.indexOf($event.keyCode) === -1 &&
      $event.key.search(/^[\d\b\.]*$/) === -1
    ) {
      $event.preventDefault();
    }
  }

  onlyText($event) {
    if ($event.key.search(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]*$/) === -1) {
      $event.preventDefault();
    }
  }

  onlyAlphaNumeric($event) {
    let letters = /^[A-Za-z]+$/;
    const arrayCodes = [8, 9, 37, 39];
    if (
      $event.keyCode === 91 ||
      $event.keyCode === 88 ||
      $event.keyCode === 67 ||
      $event.keyCode === 86 ||
      $event.keyCode === 13 ||
      $event.key.match(letters)
    ) {
      return;
    } else if (
      arrayCodes.indexOf($event.keyCode) === -1 &&
      $event.key.search(/\d\b/) === -1
    ) {
      $event.preventDefault();
    }
  }

  currencyInputChanged(value: any) {
    if (value === null || value === undefined) {
      return;
    }
    const num = value.replace(/[\$,\. ]/g, '');
    return Number(num);
  }

  markForm(form?: NgForm, toast?: boolean, time?: number, textToast?: string) {
    const timeOut = time || 12000;
    const markForm = form.form.controls;
    const message =
      textToast || 'Los campos marcados con rojo son obligatorios.';

    setTimeout(() => {
      Object.keys(markForm).forEach((control, index) => {
        form.form.controls[control].markAsTouched();
      });
      if (toast) {
        this.toastr.warning(message, null, {
          timeOut,
          positionClass: 'toast-bottom-right',
        });
      }
    }, 500);
  }

  pasteOnlyText($event: any) {
    let clipboardData = $event.clipboardData;
    let pastedText = clipboardData.getData("text");
    
    $event.preventDefault();
    return pastedText.replace(/[^a-zA-ZñÑáéíóúÁÉÍÓÚ\s]*$/g, "");
    //return pastedText.replace(/[^a-zA-Z]/g, "");
  }

  pasteOnlyNumber($event) {
    let clipboardData = $event.clipboardData;
    let pastedText = clipboardData.getData("text");
    $event.preventDefault();
    return pastedText.replace(/[^0-9]*/g, "");
  }

  maxLength(event, maxLength) {
    const value = event.target["value"];
    if (value.length + 1 > maxLength) {
      {
        if (
          event.which !== 46 &&
          event.which !== 8 &&
          event.which !== 37 &&
          event.which !== 39 &&
          event.which !== 9
        ) {
          event.preventDefault();
        }
      }
    }
  }
}
