export class MembershipModel {
    membership?:          Membership;
    prospectus?:          Prospectus;
    legalRepresentative?: LegalRepresentative;
    partners?:            LegalRepresentative[];
    documents?:           DocumentModel[];
    otpCode?:             OtpCode;
    contract?:            Contract;
}

export class LegalRepresentative {
    id:            null; /*  */
    docType:       string = ''; /*  */
    docTypeName:    string = '';
    docNumber:     string = ''; /*  */
    firstName:     string = ''; /*  */
    secondName:    null | string = ''; /*  */
    firstSurname:  string = ''; /*  */
    secondSurname: string = ''; /*  */
    email?:        string = ''; /*  */
    mobile?:       string = ''; /*  */
    membershipId:  null; /*  */
}

export class Membership {
    id:                       null;
    membershipDate:           string = ''; 
    membershipCode:           string = ''; 
    baseReference:            string = '';
    businessExecutive:        string = ''; 
    handleResources:          boolean = false; 
    politicalRecognition:     boolean = false; 
    exercisesPublicFaculty:   boolean = false; 
    politicallyExposedLinks:  boolean = false; 
    fiscalResponsibility:     string = ''; 
    withholdingTax:           boolean = false; 
    taxRate:                  string = null; 
    withholdingICA:           boolean = false; 
    icaRate:                  string = null; 
    laftPrevention:           boolean = false;
    bankEntity:               string = ''; 
    accountType:              string = ''; 
    accountNumber:            string = ''; 
    electronicInvoicingEmail: string = ''; 
    productId:                string = ''; 
    productName?:             string = ''; 
    bagValue:                 string = ''; 
    annualBond:               number = 0; 
    monthlyBond:              number = 0; 
    biannualBond:             string = '100000000'; 
    minMonthlyBilling:        string = '0'; 
    excessPerService:         string = '0'; 
    rateService:              string = ''; 
    numberInstallments:       string = ''; 
    membershipValue:          string = ''; 
    studyValue:               string = '0'; 
    studyNumber:              string = '0'; 
    checksNumber:             string = '0'; 
    monthlyBond_=             null; 
    salesTerm:                number = 0;
    affiliateSales:           number = 0;
    bondValue:                number = 0;
}

export class DocumentModel {
    Name: string;
    Description: string;
    BinaryStringBase64: any;
}
export class Prospectus {
    id:               null;
    docType:          string = ''; /*  */
    docTypeName:      string = '';
    docNumber:        string = ''; /*  */
    businessName:     string | null;
    firstName:        string = ''; /*  */
    secondName:       string = ''; /*  */
    firstSurname:     string = ''; /*  */
    secondSurname:    string = ''; /*  */
    departament:      string = ''; /*  */
    city:             string = ''; /*  */
    address:          string = ''; /*  */
    phone:            string = ''; /*  */
    hasPartners:      boolean = false; /*  */
    contactName:      string = ''; /*  */
    contactJobTittle: string = ''; /*  */
    email:            string = ''; /*  */
    mobile:           string = ''; /*  */
    role:             string = '';
}

export class OtpCode {
    phone: number;
    email: string;
    codeOTP: number;
}

export class Contract {
        Name: string;
        Description: string;
        BinaryStringBase64: string;
}

export class GeneratePdf {
    membership?:          Membership;
    prospectus?:          Prospectus;
    legalRepresentative?: LegalRepresentative;
    partners?:            LegalRepresentative[];
}


