import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentageRate'
})
export class PercentageRatePipe implements PipeTransform {

  transform(value: any, args?: any): any {

    let percentage: string = value + ' %';
    
    return percentage;
  }

}
