import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _moment from "moment";
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { element } from 'protractor';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, tap, throwIfEmpty } from 'rxjs/operators';
import { FileData } from 'src/app/models/file.model';
import { GeneralListsBonding, GeneralListsModel, ListData } from 'src/app/models/general-lists.model';
import { InitialFormModel } from 'src/app/models/initial-form-model';
import { Contract, DocumentModel, GeneratePdf, LegalRepresentative, Membership, MembershipModel, OtpCode, Prospectus } from 'src/app/models/membership.model';
import { OtpBody } from 'src/app/models/otp.model';
import { PartnerModel } from 'src/app/models/partner.model';
import { SelectModel } from 'src/app/models/selectModel';
import { BackendService } from 'src/app/services/backend.service';
import { HelperService } from 'src/app/services/helper.service';
import { ModalService } from 'src/app/services/modal.service';
import { SelectsService } from 'src/app/services/selects.service';
import { StoreService } from 'src/app/services/store.service';
import { SubjectService } from 'src/app/services/subject.service';
import Swal from 'sweetalert2';
import { ContractPdf } from './models/contract/contract';
const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss'],
})
export class RegistroComponent implements OnInit {
  @ViewChild('formMembership') formMembership: NgForm;
  @ViewChild('formPartners') formPartners: NgForm;
  public partners: PartnerModel[] = [new PartnerModel()];

  public data: any = {};
  public generalLists: GeneralListsModel;
  public generalListsBonding: GeneralListsBonding;
  public membershipModel: MembershipModel;
  
  public formpdf = new GeneratePdf;

  public departments: GeneralListsModel;
  public listCities: any;
  public initialFormValueSend: any;
  public cities: any;
  public isNIT:  boolean = false;
  public sendDataCheck : boolean;
  public sendForm64 = '';
  public listBanks: Array<ListData> = new Array<ListData>();

  public membership: Membership = new Membership();
  public membershipPdf: Membership = new Membership();
  public prospectus: Prospectus = new Prospectus();
  public prospectusPdf: Prospectus = new Prospectus();
  public legalRepresentative: LegalRepresentative;
  public partnersModel: LegalRepresentative[];
  public documents: DocumentModel[]= [];
  public otpCode: OtpCode = new  OtpCode();
  public contract: Contract[];

  public year = new Date().getFullYear();
  public minDateBirthday = new Date(1900, 1, 1);
  public maxDateBirthday = new Date();
  public fecha_nacimiento = Date.now();
  public isGlobalT: boolean = false;
  public isTrueOtp: boolean;

  public dataPep: any[];
  public dataDocumentTypes: SelectModel[];
  public dataSocietyTypes: SelectModel[];
  public dataAccountTypes: SelectModel[];
  public dataBanks: SelectModel[];
  public dataDeclaringClass: SelectModel[];
  public dataIva: SelectModel[];
  public dataDocuments = [{}];

  public storePartners: PartnerModel[];
  public stateFileList$: Observable<FileData[]>;
  public stateValidFiles$: Observable<boolean> = this.subject.stateValidFiles$;
  public stateUploadFile: any;
  public isModalUploadOpened: boolean = false;
  public stateStep: number;
  public identifierOtp: string;

  public isHasPartners: boolean;
  public baseReference: any;

  public initialForm: InitialFormModel;
  public isPartners: boolean;

  public percentageMask = { suffix: ' %', prefix: '', thousands: '', decimal: '', precision: 1, };

  public copMask = { suffix: ' cop', prefix: '$', thousands: '.', decimal: ',' };

  public quotaMask = { suffix: ' cuotas', prefix: '', thousands: '.', decimal: '', precision: 0 };

  public noMask = { suffix: '', prefix: '', thousands: '', decimal: '' };

  constructor(
    private backend: BackendService,
    public helper: HelperService,
    public modal: ModalService,
    public selects: SelectsService,
    private store: StoreService,
    private toastr: ToastrService,
    private cdRef: ChangeDetectorRef,
    private subject: SubjectService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService) {
    this.membershipModel = new MembershipModel();
    
  }

  ngOnInit() {
    
    //this.modal.show("validateOtp");
    //this.router.navigateByUrl("finalizar");
    this.spinner.show();
    forkJoin([
      this.initializeSelects(),
      this.getDepartments(),
      this.generalListBonding()
    ]).pipe(
      finalize(()=> {
        this.spinner.hide();
      })
    ).subscribe();
    

    this.membership.baseReference = this.baseReference;

    this.legalRepresentative = {
      id:            null,
      docType:       null,
      docTypeName:   null,
      docNumber:     null,
      firstName:     null,
      secondName:    null,
      firstSurname:  null,
      secondSurname: null,
      email:        '',
      mobile:       '',
      membershipId:  null,
    }

    this.prospectus = {
      id:               null,
      docType:          '',
      docTypeName:      '',
      docNumber:        '',
      businessName:     '',
      firstName:        '',
      secondName:       '',
      firstSurname:     '',
      secondSurname:    '',
      departament:      '',
      city:             '',
      address:          '',
      phone:            null,
      hasPartners:      false,
      contactName:      '',
      contactJobTittle: '',
      email:            '',
      mobile:           null,
      role:              ''
    }

    this.partnersModel = [
      {
        id: null,
        docType: "",
        docNumber: "",
        docTypeName: "",
        firstName: "",
        secondName: "",
        firstSurname: "",
        secondSurname: "",
        membershipId: null
      }
    ];

    this.contract = [
      {
        BinaryStringBase64: "",
        Name: '',
        Description: ""
      }
    ]

    this.subject.stateUploadFile$.subscribe(fileState => {
      this.stateUploadFile = fileState;
      //console.log("Subs de archivo",this.stateUploadFile);
      
    });
  }

  imprimirDato($event) {
    //console.log($event);
   //console.log($event);
  }

  get isPreviousStep(): boolean {
    return this.stateStep > 0;
  }

  get isNextStep(): boolean {
    if(this.isPartners){
      return this.stateStep < 3
    } else {
      return this.stateStep < 2
    }
    ;
  }

  get IsSocialReason(): boolean {
    return this.prospectus.docType === "3";
  }

  get socialReasonTitle(): string {
    let socialReason = (this.isNIT !== false) ? 'Razón social' : 'Nombres';
    return socialReason;
  }

  get hasPartners() {
    return this.partnersModel.length > 0
  }

  handleIsHasPartners() {
    this.spinner.show();
    this.prospectus.hasPartners = this.isHasPartners;
  }

  async handleSubmitForm(codOtp: string) {
    
    this.spinner.show();
    /* Rut */
    for(let i=0; i<this.stateUploadFile.length; i++) {
      let element = this.stateUploadFile[i];
      if(element.file.length !== 0) {
        this.documents.push({
          Name: i === 1 ? 'RUT.pdf' : element.file[0].name,
          Description: element.descriptionBack,
          BinaryStringBase64: await this.base64(element.file[0])
        });
      }
    }
    /** 
    this.documents[0].Name = 'RUT.pdf';
    this.documents[0].Description = this.stateUploadFile[1].description;
    this.documents[0].BinaryStringBase64 = await this.toBase64((this.stateUploadFile[1].file[0]));
    this.documents[0].BinaryStringBase64 = this.documents[0].BinaryStringBase64.split(',')[1];
  
    
    if(this.stateUploadFile[0].file.length !== 0) {
      this.documents[1].Name = this.stateUploadFile[0].file[0].name; 
      this.documents[1].Description = this.stateUploadFile[0].description;
      this.documents[1].BinaryStringBase64 = await this.toBase64(this.stateUploadFile[0].file[0]);
      this.documents[1].BinaryStringBase64 = this.documents[1].BinaryStringBase64.split(',')[1];
    }
    
    this.documents[2].Name = this.stateUploadFile[2].file[0].name;
    this.documents[2].Description = this.stateUploadFile[2].description;
    this.documents[2].BinaryStringBase64 = await this.toBase64(this.stateUploadFile[2].file[0]);
    this.documents[2].BinaryStringBase64 = this.documents[2].BinaryStringBase64.split(',')[1];
    
    this.documents[3].Name = this.stateUploadFile[3].file[0].name;
    this.documents[3].Description = this.stateUploadFile[3].description;
    this.documents[3].BinaryStringBase64 = await this.toBase64(this.stateUploadFile[3].file[0]);
    this.documents[3].BinaryStringBase64 = this.documents[3].BinaryStringBase64.split(',')[1];

    
    this.documents[4].Name = this.stateUploadFile[4].file[0].name;
    this.documents[4].Description = this.stateUploadFile[4].description;
    this.documents[4].BinaryStringBase64 = await this.toBase64(this.stateUploadFile[4].file[0]);
    this.documents[4].BinaryStringBase64 = this.documents[4].BinaryStringBase64.split(',')[1];
    */
    this.contract[0].BinaryStringBase64 =  this.sendForm64;
    this.contract[0].Name = 'contrato.pdf';
    this.contract[0].Description = "";


    this.otpCode.codeOTP = parseInt(codOtp);
    this.otpCode.email = this.prospectus.email ;
    this.otpCode.phone = parseInt(this.prospectus.mobile);
    let formBody: any;
    // colocar revision

    if(this.documents.length !== 0) {
      if(!this.isPartners )  {
        this.prospectus.firstName = this.initialFormValueSend.prospectus.firstName;
        this.prospectus.firstSurname = this.initialFormValueSend.prospectus.firstSurname;
        
        formBody = {
          membership: this.membership,
          prospectus: this.prospectus,
          partners: [],
          documents: this.documents,
          otpCode: this.otpCode,
          contract: this.contract
          }
        } else if(this.partnersModel.length >= 1 &&  this.partnersModel[0].id === null) {
          this.prospectus.firstName = null;
          formBody = {
            membership: this.membership,
            prospectus: this.prospectus,
            legalRepresentative: this.legalRepresentative,
            partners: [],
            documents: this.documents,
            otpCode: this.otpCode,
            contract: this.contract
            }
        } else {
        this.prospectus.firstName = null;
        formBody = {
          membership: this.membership,
          prospectus: this.prospectus,
          legalRepresentative: this.legalRepresentative,
          partners: this.partnersModel,
          documents: this.documents,
          otpCode: this.otpCode,
          contract: this.contract
        }
      }
    } else {
      console.log( 'error docs')
      this.spinner.hide();
    }
    
    

    if(this.legalRepresentative.docType === null) {
      delete formBody.legalRepresentative;
    }

    this.membership.membershipDate = new moment(this.membership.membershipDate).format("YYYY-MM-DD HH:mm:ss");

    if(this.prospectus.docType === "8") {
      this.prospectus.docType = "RAZÓN SOCIAL";
      this.prospectus.firstName = null;
      this.prospectus.secondName = null;
      this.prospectus.firstSurname = null;
      this.prospectus.secondSurname = null;
    }

    this.backend.saveForm(formBody).subscribe((response)=> {
      //console.log(response,'respuesta formulario');
      this.spinner.hide();
      this.sendDataCheck = true;
      Swal.fire({ 
        title: 'Se guardó con éxito',   
        type: 'info'
      })
    },(error)=> {
      //console.log(error,'error formulario');
      this.spinner.hide();
      this.sendDataCheck = false;
      Swal.fire({ 
        text: error.error.message,
        title: 'Error al guardar formulario',   
        type: 'error'
      })
    })
  }

  async base64(element) {
    let doc = await this.toBase64(element);
    let docSend = doc.split(',');
    return  docSend[1];
    
  }

  toBase64(file: File): any {

    return new Promise((resolve, reject) => {
    
      const reader = new FileReader();
      
      reader.readAsDataURL(file);
      
      reader.onload = () => resolve(reader.result);
      
      reader.onerror = error => reject(error);
    
    });
    
    }

  showModalPdf() {
    this.modal.show('pdf', 
    {
      base64: true
    });
  }

  showConsultationPolicy() {
    this.modal.show('consultationPolicy')
  }

  showdDataProtection() {
    this.modal.show('dataProtection')
  }
  
  showElectronicSignature() {
    this.modal.show('electronicSignature')
  }

  showTermsModal() {
    this.modal.show('terms');
  }

  initializeSelects() {
    this.dataPep = this.selects.dataPep || [];
    this.dataDocumentTypes = this.selects.dataDocumentTypes || [];
    this.dataSocietyTypes = this.selects.dataSocietyTypes || [];
    this.dataAccountTypes = this.selects.dataAccountTypes || [];
    this.dataBanks = this.selects.dataBanks || [];
    this.dataDeclaringClass = this.selects.dataDeclaringClass || [];
    this.dataIva = this.selects.dataIva || [];

    this.subject.stateStep$.subscribe(step => {
      this.stateStep = step;
    });

    this.store.storePartners$.subscribe((partners: PartnerModel[]) => {
      this.storePartners = partners || [];
    });
    
    return this.backend.getGeneralLists()
      .pipe(
        filter(response => response !== null && response !== undefined),
        tap((response: GeneralListsModel) => {
          if(response !== null && response !== undefined) {
            this.generalLists = response;
            this.generalLists.data[0].banks.forEach((element: ListData) => {
              if(element.state === 'SHOW') {
                this.listBanks.push(element);
              }
            });
            this.getBaseReference();
          }
        }),
        catchError(error => {
          Swal.fire({ 
            title: 'error al cargar listas',   
            type: 'error'
          })
          return throwError(error);
        })
      );
  }

  generalListBonding() {

    return this.backend.getGeneralListsBonding()
      .pipe(
        tap((response: GeneralListsBonding) => {
          this.generalListsBonding = response;
        }),
        catchError(error => {
          Swal.fire({ 
            title: 'error al cargar listas',   
            type: 'error'
          });

          return throwError(error);
        })   
      );
  }



  parseInitialForm(initialFormValue: any) {
    if(initialFormValue) {
      this.initialFormValueSend = initialFormValue;
      this.membership.membershipDate = new moment(initialFormValue.membershipForm.membershipDate).format();
      this.membership.membershipCode = initialFormValue.membershipForm.membershipCode;
      this.membership.baseReference = initialFormValue.membershipForm.baseReference;
      this.membership.businessExecutive = initialFormValue.membershipForm.businessExecutive;
      this.membership.productId = initialFormValue.membershipForm.productId;
      this.membership.productName = initialFormValue.membershipForm.productName;
      this.membership.studyValue = initialFormValue.membershipForm.studyValue;
      this.membership.studyNumber = initialFormValue.membershipForm.studyNumber;
      this.membership.checksNumber = initialFormValue.membershipForm.checksNumber;
      this.membership.monthlyBond = ((initialFormValue.membershipForm.monthlyBond)*(1.19));
      this.membership.bagValue = initialFormValue.membershipForm.bagValue;
      this.membership.bondValue = initialFormValue.membershipForm.bondValue;
      this.membership.annualBond = initialFormValue.membershipForm.annualBond;
      this.membership.biannualBond = initialFormValue.membershipForm.biannualBond;
      this.membership.minMonthlyBilling = initialFormValue.membershipForm.minMonthlyBilling;
      this.membership.excessPerService = initialFormValue.membershipForm.excessPerService;
      this.membership.rateService = initialFormValue.membershipForm.rateService;
      this.membership.numberInstallments = initialFormValue.membershipForm.numberInstallments;
      this.membership.membershipValue = initialFormValue.membershipForm.membershipValue;
      this.membership.salesTerm = initialFormValue.membershipForm.salesTerm;
      this.membership.affiliateSales = (initialFormValue.membershipForm.affiliateSales !== null) ? initialFormValue.membershipForm.affiliateSales : 0;

      if(initialFormValue.prospectus) {
        if (initialFormValue.prospectus.docTypeName === "NIT") {
          this.isPartners = true;
        } else {
          this.isPartners = false;
        }
        this.isGlobal(this.membership.productName);

        this.prospectus.docType = this.getDocType(initialFormValue.prospectus.docType, true);
        this.prospectus.docTypeName = initialFormValue.prospectus.docTypeName;
        this.prospectus.docNumber = initialFormValue.prospectus.docNumber;
        this.prospectus.role = initialFormValue.prospectus.role;
        
        if(this.isPartners){
          this.prospectus.businessName = initialFormValue.prospectus.firstName;
          this.prospectus.firstName = initialFormValue.prospectus.firstName;
          this.prospectus.firstSurname = null;
          this.prospectus.secondName = null;
          this.prospectus.secondSurname = null;
        } else {
          this.prospectus.businessName = null;
          this.prospectus.firstName = `${initialFormValue.prospectus.firstName} ${initialFormValue.prospectus.secondName}`;
          this.prospectus.firstSurname = `${initialFormValue.prospectus.firstSurname} ${initialFormValue.prospectus.secondSurname}`;
          this.prospectus.secondName = initialFormValue.prospectus.secondName;
          this.prospectus.secondSurname = initialFormValue.prospectus.secondSurname;
        }
      }

      if(initialFormValue.legalRep) {
        this.legalRepresentative.docType = this.getDocType(initialFormValue.legalRep.docType);
        this.legalRepresentative.docTypeName = initialFormValue.legalRep.docTypeName;
        this.legalRepresentative.docNumber = initialFormValue.legalRep.docNumber;
        this.legalRepresentative.firstName = initialFormValue.legalRep.firstName;
        this.legalRepresentative.secondName = initialFormValue.legalRep.secondName;
        this.legalRepresentative.firstSurname = initialFormValue.legalRep.firstSurname;
        this.legalRepresentative.secondSurname = initialFormValue.legalRep.secondSurname;
        this.legalRepresentative.email = initialFormValue.legalRep.email;
        this.legalRepresentative.mobile = initialFormValue.legalRep.mobile;
      }

      if (this.partnersModel && this.partnersModel.length > 0) {
        
        this.partnersModel = initialFormValue.partners;
        this.partnersModel.forEach((partner, index) => {
          this.partnersModel[index].docType = this.getDocType(partner.docType)
          
        })
        //console.log(this.partnersModel);
      }

      if(this.partnersModel && this.partnersModel.length === 0) {
        this.addPartner();
      }
      /* docType.value; */
    }
  }

  isGlobal(dataName: string) {
    let name = 'global total' 
    const nameGlobal = dataName.toLocaleLowerCase();
    if(name.includes(nameGlobal)) {
      this.isGlobalT = true;
    } else {
      this.isGlobalT = false;
    }
    //console.log(this.isGlobalT);
  }

  getDocType(documentType: string, isProspectus?: boolean): any {
    let selectedDocType;
    if(this.generalLists.data) {
      this.generalLists.data[0].documentTypes.forEach(docType => {        
        if(docType.value === documentType) {
          selectedDocType = docType.value;
          if(isProspectus && docType.description === "NIT") {    
            this.getDocTypeName(isProspectus);
          } 
        }
      });
    }
    
    return selectedDocType;
  }

  getDocTypeName(isProspectus: boolean): void {
    this.isNIT = (isProspectus) ? true : false;
  }

  handleHoldingTax() {
    this.membership.taxRate = (!this.membership.withholdingTax) ? '' : this.membership.taxRate
    //console.log(this.membership.withholdingTax);
    
  }

  handleHoldingICA() {
    this.membership.icaRate = (!this.membership.withholdingICA) ? '' : this.membership.icaRate
  }

  handleGenerateOtpCode($event) {
    this.spinner.show();
    let bodyOtp: OtpBody = new OtpBody();
    if (this.isNIT) {
      bodyOtp.celular = this.legalRepresentative.mobile;
      bodyOtp.correo = this.legalRepresentative.email;
     
    } else {
      bodyOtp.celular = this.prospectus.mobile;
      bodyOtp.correo = this.prospectus.email;
    }

    this.modal.close();
    this.backend.generateOtpCode(bodyOtp).subscribe((response: any) => {
      if(response.exitoso) {
        this.spinner.hide();
        this.isTrueOtp = true;
        this.identifierOtp = response.Identificador;
        this.modal.show('validateOtp');
        Swal.fire({ 
          text: response.DescripcionRespuesta,
          title: 'El código se generó correctamente, por favor revisar su celular o correo',   
          type: 'info'
        });
      } else {
        this.spinner.hide();
        this.isTrueOtp = false;
        this.modal.show('validateOtp');
        Swal.fire({ 
          title: 'Error al generar Código',   
          type: 'error'
        });
      }
    },(err: any) => {
      this.spinner.hide();
      this.isTrueOtp = false;
      this.modal.show('validateOtp');
      Swal.fire({ 
        title: 'Error al generar Código',   
        type: 'error'
      });
    })
  }

  generateOtpCode() {
    this.spinner.show();
    let bodyOtp: OtpBody = {
      celular: this.prospectus.mobile,
      correo: this.prospectus.email
    };
    this.backend.generateOtpCode(bodyOtp).subscribe((response: any) => {
      if(response.exitoso) {
        this.spinner.hide();
        this.isTrueOtp = true;
        this.identifierOtp = response.Identificador;
        Swal.fire({ 
          text: response.DescripcionRespuesta,
          title: 'El código se generó correctamente, por favor revisar su celular o correo',   
          type: 'info'
        });
      } else {
        this.spinner.hide();
        this.isTrueOtp = false;
        Swal.fire({ 
          title: 'Error al generar Código',   
          type: 'error'
        });
      }
    },(err: any) => {
      this.spinner.hide();
      this.isTrueOtp = false;
      Swal.fire({ 
        title: 'Error al generar Código',   
        type: 'error'
      });
    })
  }

  getBaseReference() {
    this.route.queryParams
      .subscribe(params => {
        this.baseReference = params.baseReference;
      })
    this.backend.getInitialForm(this.baseReference).subscribe((responseInit: InitialFormModel) => {
      this.initialForm = responseInit;
      if( responseInit.data !== undefined && responseInit.data !== null) {
        /* this.backend.parseInitialForm(responseInit.data) */
        this.parseInitialForm(responseInit.data[0]);
        this.stateFileList$ = this.subject.getStateUploadFile(this.isPartners);
      } else {
        Swal.fire({ 
          title: responseInit.message,   
          type: 'error'
        });
      }
      //console.log(this.initialForm, 'form');
    },(err: any) => {
      //console.log(err)
      Swal.fire({ 
        text: err.error.message,
        title: 'error al cargar datos del formulario',   
        type: 'error'
      });
    });
  }

  

  changeToggle() {
    //console.log(this.membership);
  }

  getDepartments() {
    return this.backend.getGeneralLists()
      .pipe(
        tap((response: any) => {
          if(response !== null && response !== undefined) {
            this.departments = response.data[0].departaments|| null;
          }
        }),
        catchError(error => {
          Swal.fire({ 
            title: 'error al cargar departamentos',   
            type: 'error'
          });
          return throwError(error);
        })
      )
  }

  selectDeparment(selectedDepartment: any) {
    //console.log("Se ha activado esto");
    
    this.prospectus.city = "";
    this.backend.getCities(selectedDepartment).subscribe(
      (municipalities: any) => {
        if(municipalities.data) {
          //console.log(municipalities)
          this.listCities = municipalities.data[0].cities || [];
          //console.log("Data de ciudades", this.listCities);
          
        }
      },
      (err) => {
        Swal.fire({ 
          title: 'error al cargar ciudades',   
          type: 'error'
        })
        this.listCities = [];
      });
  }

  showUploadModal() {
    this.modal.show("fileList");
    this.isModalUploadOpened = true;
  }



  showOtpModal() {
    this.membershipPdf = {...this.membership};
    this.prospectusPdf = {...this.prospectus};
    this.prospectusPdf.firstName = this.initialFormValueSend.prospectus.firstName;
    this.prospectusPdf.firstSurname =  this.initialFormValueSend.prospectus.firstSurname;
    this.generalLists.data[0].banks.forEach(element => {
      if (element.value === this.membershipPdf.bankEntity) {
        this.membershipPdf.bankEntity = element.description;
      }
    });
    this.generalLists.data[0].accountTypes.forEach(element => {
      if (element.value === this.membershipPdf.accountType) {
        this.membershipPdf.accountType = element.description;
      }
    });
    this.listCities.forEach(element => {
      if(element.value === this.prospectusPdf.city) {
        this.prospectusPdf.city = element.description;
      }
    })
    
    this.membershipPdf.membershipDate = new moment(this.membership.membershipDate).format("YYYY-MM-DD HH:mm:ss");

    if(!this.isPartners )  {
      this.formpdf.membership = this.membershipPdf;
      this.formpdf.prospectus = this.prospectusPdf;
      this.formpdf.partners = [];
        
      } else if(this.partnersModel.length >= 1 &&  this.partnersModel[0].id === null) {
        this.formpdf.membership = this.membershipPdf;
        this.prospectus.firstName = null;
        this.formpdf.prospectus = this.prospectusPdf;
        this.formpdf.legalRepresentative = this.legalRepresentative;
        this.formpdf.partners = [];
        
      } else {
        this.formpdf.membership = this.membershipPdf;
        this.prospectus.firstName = null;
        this.formpdf.prospectus = this.prospectusPdf;
        this.formpdf.legalRepresentative = this.legalRepresentative;
        this.formpdf.partners = this.partnersModel;
    }
    
    this.modal.show("pdf");
  }

  showModal() {
    this.modal.show("fileList")
  }

  identify(index, item) {
    return item.id;
  }

  send(form: NgForm) {
    
  }

  nextStep() {
      this.subject.changeStateStep(this.stateStep + 1);
  }

  previousStep() {
      this.subject.changeStateStep(this.stateStep - 1);
  }


  addPartner(idx?: number) {

    const partnerModel = {
        id: null,
        docType: "",
        docTypeName: "",
        docNumber: "",
        firstName: "",
        secondName: "",
        firstSurname: "",
        secondSurname: "",
        membershipId: null
      }

    if (this.partners && this.partners.length >= 5) {
      this.toastr.info('Puede agregar máximo 5 socios.', null);
      return;
    }

    //const partner: PartnerModel = new PartnerModel();
    //this.store.addPartner({ ...partner });
    this.partnersModel.push({...partnerModel})
    this.markForm();
    this.cdRef.detectChanges();
  }

  deletePartner() {    
    if(this.storePartners && this.storePartners.length <= 1) {
      this.toastr.warning("Debe existir al menos 1 socio.")
    } else {
      this.store.deletePartner();
      this.cdRef.detectChanges();
    }
  }

  markForm() {
    this.helper.markForm(this.formMembership, true);
  }

  sendPdfm64(data: string) {
    this.sendForm64 = data;
  }
}
