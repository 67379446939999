import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { otpBodyValidate } from 'src/app/models/otp.model';
import { BackendService } from 'src/app/services/backend.service';
import { HelperService } from 'src/app/services/helper.service';
import { ModalService } from 'src/app/services/modal.service';
import { SubjectService } from 'src/app/services/subject.service';
import { OtpRequestModel } from '../../pages/registro/models/request/otp.request.model';
import { OtpResponseModel } from '../../pages/registro/models/response/otp.response.model';
import { RegisterService } from '../../services/register.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

class formOtpModel {
  firstNumber: string = '';
  secondNumber: string = '';
  thirdNumber: string = '';
  fourNumber: string = '';
}
@Component({
  selector: 'app-modal-otp',
  templateUrl: './modal-otp.component.html',
  styleUrls: ['./modal-otp.component.scss']
})
export class ModalOtpComponent implements OnInit {
  @ViewChild('firstNumber') firstNumber: ElementRef;
  @ViewChild('secondNumber') secondNumber: ElementRef;
  @ViewChild('thirdNumber') thirdNumber: ElementRef;
  @ViewChild('fourNumber') fourNumber: ElementRef;

  @Output() submitEvent: EventEmitter<any> = new EventEmitter();
  @Output() saveForm: EventEmitter<any> = new EventEmitter();
  @Output() generateOtpCode: EventEmitter<string> = new EventEmitter();


  /* @Input() isUserInformation: boolean;
  @Input() isNoIdentify: boolean; */
  @Input() check: boolean;
  @Input() identifierOtp: string;
  @Input() 
  set sendDataCheck (sendDataCheck: any) {
    if (sendDataCheck) {
      this.validateOtpCheck = sendDataCheck;
    }
  }

  get sendDataFilter () {
    return this.validateOtpCheck;
  }

  public isError: boolean = false;
  public bodyOtp: otpBodyValidate = new otpBodyValidate();
  public validateOtpCheck = false;
  public checkedSend: boolean = false;
  public OtpForm: formOtpModel = new formOtpModel();
  public otpRequest: OtpRequestModel = new OtpRequestModel();

  constructor(
    public modal: ModalService, 
    public helper: HelperService, 
    private _register: RegisterService,
    private cdRef: ChangeDetectorRef,
    private backend: BackendService,
    private spinner: NgxSpinnerService,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  sign(): void {
    this._register.signOtp(this.otpRequest).subscribe(
      (response: OtpResponseModel) => {
        //console.log(response);

      },
      (error: any) => {
        this.isError = true;
        // this.helper.showMessage(error.error.message);
      }
    );
  }

  focus(index: number) {
    const validInputs = Object.keys(this.OtpForm);

    if (this.OtpForm[`${validInputs[index]}`] !== '') {
      //console.log(this.OtpForm[`${validInputs[index]}`]);
      this[`${validInputs[index + 1]}`].nativeElement.focus();
      this.cdRef.detectChanges();
    }
  }
  signOtp() {
    this.spinner.show();
    this.bodyOtp.textoOTP = `${this.OtpForm.firstNumber}${this.OtpForm.secondNumber}${this.OtpForm.thirdNumber}${this.OtpForm.fourNumber}`;
    this.bodyOtp.identificador = this.identifierOtp;
    this.backend.otpValidate(this.bodyOtp).subscribe((response: any) => {
      if(response.exitoso && response.EsValidoOTP) {
        this.spinner.hide();
        //this.validateOtpCheck = true;
        this.identifierOtp = response.Identificador;
        this.saveForm.emit(this.bodyOtp.textoOTP);
      } else {
        this.spinner.hide();
        this.validateOtpCheck = false;
        this.resetForm();
        Swal.fire({ 
          title: 'El código no es válido',   
          type: 'error'
        });
      }
    },(err: any) => {
      this.spinner.hide();
      this.validateOtpCheck = false;
      this.resetForm();
      Swal.fire({ 
        title: 'Error al validar código',   
        type: 'error'
      });
    })
    
  }

  continue() {
    this.validateOtpCheck = true;
    this.bodyOtp.textoOTP = '1234'
    this.saveForm.emit(this.bodyOtp.textoOTP);
  }

  resendOtp() {
    this.generateOtpCode.emit();
    this.resetForm();
  }

  resetForm() {
    for (const key in this.OtpForm) {
      this.OtpForm[key] = "";
    }
  }

  closeModal() {
    this.modal.close();
    this.resetForm();
  }

  finish() {
    this.modal.close();
    this.router.navigateByUrl("finalizar");
  }
}
