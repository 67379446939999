// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // apiUrl: 'http://200.41.76.26:8080/AdminProysCovinoc/servicios',
  //  urlApi:  'http://localhost:3000',
  urlCoviUtils: 'https://wscovp.c0v.co',
  otpApi: 'https://wsbolp.c0v.co/olimpia',
  urlDigital: 'https://wscovpsdo.c0v.co/digital-bonding',
  userOtp: 'CLIENT_APP-19921ef5:23bHloreqW@7',
  userApp: 'CLIENT_APP-VinculacionDigital_53:OC8nOEa3zvQOXBSNVyqntKUdpMk2cxr7XZQGmzluvUJumFO+8ttArYx4Y8MST9Bg',
  //otpApi: ' https://dev.node.covi.pw/olimpiadev',
  //urlApi:  'https://bcmedicapp.covinoc.com',
  production: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
