import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SelectsService {

public test = { label: "Cédula de ciudadanía", value: "C" };

  public dataDocumentTypes = [
    { label: "Cédula de ciudadanía", value: "C" },
    { label: "Cédula de extranjería", value: "E" },
    { label: "Nit", value: "N" },
    { label: "Pasaporte", value: "P" },
    { label: "Tarjeta identidad", value: "T" },
  ];

  public dataSocietyTypes = [
    { label: "S.C. Accionest", value: "N" },
    { label: "S.C. Simple", value: "N" },
    { label: "Consorcio", value: "N" },
    { label: "Limitada", value: "N" },
    { label: "PN Comerciante", value: "N" },
    { label: "S. Anónima", value: "N" },
    { label: "S. Colectiva", value: "N" },
    { label: "S.A.S", value: "N" },
    { label: "Unión Temporala", value: "N" },
    { label: "I.E.S", value: "N" },
    { label: "E. Unipersonal", value: "N" },
  ];

  public dataBanks = [
    { label: "banco de occidente", value: "N" },
    { label: "banco agrario", value: "N" },
    { label: "banco av villas", value: "N" },
    { label: "banco bbva", value: "N" },
    { label: "banco caja social", value: "N" },
    { label: "banco coomeva1banco de bogotá", value: "N" },
    { label: "banco falabella7banco gnb sudameris", value: "N" },
    { label: "banco pichincha2banco popular", value: "N" },
    { label: "banco w5bancolombia citibank", value: "N" },
    { label: "colpatria", value: "N" },
    { label: "corpbanca", value: "N" },
    { label: "davivienda", value: "N" },
    { label: "itaú", value: "N" },
    { label: "scotiabank", value: "N" },
  ];

  public dataAccountTypes = [
    { label: "Cta. Ahorros", value: "Cta. Ahorros" },
    { label: "Cta. Corriente", value: "Cta. Corriente" },
  ];

  public dataDeclaringClass = [
    { label: "Contribuyente", value: "Contribuyente" },
    { label: "No Contribuyente", value: "No Contribuyente" },
    {
      label: "Régimen tributario especial",
      value: "Régimen tributario especial",
    },
    { label: "Gran contribuyente", value: "Gran contribuyente" },
  ];

  public dataIva = [
    { label: "Común", value: "Común" },
    { label: "Simplificado", value: "Simplificado" },
    { label: "No responsable", value: "No responsable" },
  ];

  public dataPep: any = [
    {
      id: "¿Maneja recursos públicos?",
      description: "¿Maneja recursos públicos?",
      check: false,
    },
    {
      id: "¿Por su actividad u oficio tiene reconocimiento político general?",
      description:
        "¿Por su actividad u oficio tiene reconocimiento político general?",
      check: false,
    },
    {
      id: "¿Por su cargo o actividad ejerce algún grado de poder público?",
      description:
        "¿Por su cargo o actividad ejerce algún grado de poder público?",
      check: false,
    },
    {
      id: "¿Existe algún vínculo entre usted y una persona considerada públicamente expuesta?",
      description:
        "¿Existe algún vínculo entre usted y una persona considerada públicamente expuesta?",
      check: false,
    },
    {
      id: "¿Obligado a cumplir prevención LAFT?",
      description:
        "¿Obligado a cumplir prevención LAFT?",
      check: false,
    },
  ];

  constructor() { }
}
