import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';

/*Switch*/
import { UiSwitchModule } from 'ngx-ui-switch';

/*Toaster*/
import { ToastrModule } from 'ngx-toastr';

/*Spinner*/
import { NgxSpinnerModule } from 'ngx-spinner';

/*DatePicker*/
import { OwlDateTimeModule, OwlNativeDateTimeModule, OwlDateTimeIntl } from 'ng-pick-datetime';
import { NgModule, LOCALE_ID  } from '@angular/core';
import { OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { registerLocaleData } from '@angular/common';
import localeEsCO from '@angular/common/locales/es-CO';

/*HTTP*/
import { HttpClientModule } from '@angular/common/http';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

/* Virtual Scroll */
import { ScrollingModule } from '@angular/cdk/scrolling';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

/* Rutas */
import { APP_ROUTING } from './app.routes';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

// Directivas
import { OutsideDirective } from './directives/outside.directive';
import { } from '@angular/cdk/keycodes';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { AuthService } from './services/auth.service';
import { FilterPipe } from './pipes/filter.pipe';
import { RegistroComponent } from './pages/registro/registro.component';
import { FinishFormComponent } from './pages/finish-form/finish-form.component';
import { WhatsappComponent } from './components/whatsapp/whatsapp.component';
import { ModalOtpComponent } from './components/modal-otp/modal-otp.component';
import { FileListComponent } from './components/file-list/file-list.component';
import { CustomDropzonePreviewComponent } from './components/custom-dropzone-preview/custom-dropzone-preview.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { StepperComponent } from './components/stepper/stepper.component';
import { ModalTermsComponent } from './components/modal-terms/modal-terms.component';
import { ModalPdfComponent } from './components/modal-pdf/modal-pdf.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { PercentageRatePipe } from './pipes/percentage-rate.pipe';
import { ModalDataProtectionComponent } from './components/modal-data-protection/modal-data-protection.component';
import { ModalConsultationPolicyComponent } from './components/modal-consultation-policy/modal-consultation-policy.component';
import { ModalElectronicSignatureComponent } from './components/modal-electronic-signature/modal-electronic-signature.component';

registerLocaleData(localeEsCO, 'es-Co');

export class DefaultIntl extends OwlDateTimeIntl  {
  rangeFromLabel = 'Desde';
  rangeToLabel = 'Hasta';
  hour12AMLabel = 'AM';
  hour12PMLabel = 'PM';
  prevMultiYearLabel = '21 años atrás';
  nextMultiYearLabel = 'Próximos 21 años';
}

@NgModule({
  declarations: [
    AppComponent,
    OutsideDirective,
    OnlyNumberDirective,
    FilterPipe,
    RegistroComponent,
    FinishFormComponent,
    WhatsappComponent,
    ModalOtpComponent,
    FileListComponent,
    CustomDropzonePreviewComponent,
    UploadFileComponent,
    StepperComponent,
    ModalTermsComponent,
    ModalPdfComponent,
    PercentageRatePipe,
    ModalDataProtectionComponent,
    ModalConsultationPolicyComponent,
    ModalElectronicSignatureComponent
    
    
  ],
  imports: [
    BrowserModule,
    NgMultiSelectDropDownModule.forRoot(),
    ScrollingModule,
    CurrencyMaskModule,
    UiSwitchModule.forRoot({
      size: 'small',
      color: 'rgb(61, 160, 48)',
      switchColor: '#FFFFFF',
      defaultBgColor: 'rgb(151, 151, 151)',
      defaultBoColor : '#dadada',
      checkedLabel: 'Si',
      uncheckedLabel: 'No'
    }),
    Ng2SearchPipeModule,
    CommonModule,
    APP_ROUTING,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxDropzoneModule,
    NgxSpinnerModule,
    NgxExtendedPdfViewerModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      progressBar: true,
      progressAnimation: 'decreasing',
      maxOpened: 1,
      autoDismiss: true,
      closeButton: true
    })
  ],
  providers: [
    AuthService,
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'es' },
    { provide: OwlDateTimeIntl, useClass: DefaultIntl },
    { provide: LOCALE_ID, useValue: 'es-Co' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
