import {
  Component, DoCheck, HostListener, OnDestroy, OnInit
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { StorageService } from './services/storage.service';

declare let $: any;
declare var gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, DoCheck, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  public identity: any;
  public year: number = Date.now();
  public toggleNav = false;
  public request = null;
  public logoColapse = false;
  public title = 'Formulario de afiliación';
  public userData: any;
  public isLogin: any;
  public isLogged: boolean;
  public administrator = false;
  public toggleDropdown = false;
  public analytics: any;
  public isShow: boolean;
  public topPosToStartShowing = 100;

  public innerWidth: any;
  public screenHeight: any;
  public isShowWidth: boolean;
  public isShowHeigth: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenHeight = window.innerHeight;
    this.innerWidth = window.innerWidth;
    this.isShowHeigth = this.screenHeight >= 1040 ? true : false;
    this.isShowWidth = this.innerWidth >= 900 ? true : false;
  }

  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    this.isShow = scrollPosition >= this.topPosToStartShowing ? true : false;
  }

  constructor(
    private router: Router,
    public auth: AuthService,
    private toastr: ToastrService,
    private authService: AuthService,
    public storageService: StorageService,
  ) {
    this.handleAnalytics();
  }

  ngOnInit() {
    console.info(new Date(2024, 8, 30), 'commit 41e36e93447f94527d8806856387defd494e68fd, ambiente: Test ', "Inplementacion de @font-face y quitar google fonts");
    
    this.handleLocalstorage();
  }

  

  ngDoCheck() {
    this.handleLocalstorage();
    this.toggleNav = this.router.url == '/login' ? true : false;
    this.rol();
  }

  handleLocalstorage() {
    this.identity = JSON.parse(localStorage.getItem('identityMA'));
    this.analytics = JSON.parse(localStorage.getItem('identityMA'));
  }

  handleAnalytics() {
    const navEndEvent$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );

    navEndEvent$.subscribe((event: NavigationEnd) => {
      gtag('config', 'G-P0ZG3R71G6', {
        page_path: event.urlAfterRedirects,
      });
    });
  }

  recommendations() {
    (window as any).gtag('event', 'recomendaciones', {
      event_category: `${this.analytics.TIPO_DOCUMENTO}${this.analytics.DOCUMENTO}`,
      event_label: ``,
    });
  }

  rol() {
    if (
      this.authService.rolValidate() === false ||
      this.authService.rolValidate() === undefined ||
      this.authService.rolValidate() === null
    ) {
      this.administrator = false;
    } else {
      this.administrator = true;
    }
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  hoverMenu(state: boolean) {
    state ? (this.toggleDropdown = true) : (this.toggleDropdown = false);
  }

  // TODO: Cross browsing
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  logOut() {
    localStorage.removeItem('identityMA');
    localStorage.removeItem('userMA');
    localStorage.removeItem('TokenMA');
    localStorage.removeItem('RolMA');
    this.toggleNav = false;
    this.router.navigate(['/login']);
    (window as any).gtag('event', 'cerrar_sesion', {
      event_category: `${this.analytics.TIPO_DOCUMENTO}${this.analytics.DOCUMENTO}`,
      event_label: ``,
    });
  }

  toastNotification(titulo: string, descripcion: string) {
    this.toastr.success(titulo, descripcion);
  }
}
