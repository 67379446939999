import { RouterModule, Routes } from '@angular/router';
import { FinishFormComponent } from './pages/finish-form/finish-form.component';
import { RegistroComponent } from './pages/registro/registro.component';

const APP_ROUTES: Routes = [
    { path: 'registro', component: RegistroComponent },
    { path: 'finalizar', component: FinishFormComponent},
    { path: '**', pathMatch: 'full', redirectTo:  'registro' },
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES, { useHash: true, anchorScrolling: 'enabled'});
