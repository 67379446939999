import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { GeneralListsBonding, GeneralListsModel } from "../models/general-lists.model";

import { InitialFormModel}  from "../models/initial-form-model";
import { OtpBody, otpBodyValidate } from "../models/otp.model";
import { GeneratePdf, MembershipModel } from "../models/membership.model";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class BackendService {
  private urlDigital: string;
  private urlCoviUtils: string;
  private userOtp: string;
  private userApp: string;
  public identity: any;
  public token: any;
  public userData: any;
  public rol: string;
  public headers: any;

  constructor(private http: HttpClient) {
    this.urlCoviUtils = environment.urlCoviUtils;
    this.urlDigital = environment.urlDigital;
    this.userOtp = environment.userOtp;
    this.userApp = environment.userApp;
    this.userData = JSON.parse(localStorage.getItem("identityMA"));
    this.rol = localStorage.getItem("RolMA");
    this.headers = new HttpHeaders();
    this.headers = this.headers.set("Content-Type", "application/json");
    this.headers = this.headers.set("token", localStorage.getItem("TokenMA"));
  }

  public saveAffiliateForm(body: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.userApp),
    });
    const options = { headers };
    return this.http.post(this.urlDigital + "/membership/save", body, options);
  }

  public getGeneralListsBonding(): Observable<GeneralListsBonding> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.userApp),
    });

    let options = { headers }

    return this.http.get<GeneralListsBonding>(this.urlDigital + "/lists", options)
  }

  public getGeneralLists(): Observable<GeneralListsModel> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
    });

    let options = { headers }

    return this.http.get<GeneralListsModel>(this.urlCoviUtils+ "/covi-utils/lists", options)
  }

  /** 
  getMunicipalities() {
    return this.http.get(`${this}/lists/municipalities`);
  }
  */

  getCities(departmentId: string) {
    /* const headers = this.headers.append("Content-Type", "application/json"); */
    const params = { municipaly: departmentId }
    const options = {
      params
    };
    return this.http.get(`${this.urlCoviUtils}/covi-utils/lists/cities`, options);
  }

  saveForm(body: any) {
    
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.userApp),
    });
    const options = { headers };
    return this.http.post(`${this.urlDigital}/membership/save`, body, options);
  }

  generateOtpCode(otpBody: OtpBody) {
    const headers: HttpHeaders = new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Basic ' + btoa(this.userOtp),
        
        //"Authorization":`Basic Q0xJRU5UX0FQUC0xOTkyMWVmNTo0MmJKc2t1aWRCQDA=`,
      });
     
    const options = { headers };
    return this.http.post(`${environment.otpApi}/api/v1/otp/generar`, otpBody , options);
  }

  public getInitialForm(baseReference: string): Observable<InitialFormModel> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.userApp),
    });

    const params = { baseReference }
    let options = { headers, params }

    return this.http.get<InitialFormModel>(`${this.urlDigital}/membership/getFormValues`, options);
  }

  parseInitialForm(initialFormValue: any) {
    return {
      membership: "hola",
      prospectus: "como"
    }
  }

  otpValidate(data: otpBodyValidate) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.userOtp)
      //"Authorization":`Basic Q0xJRU5UX0FQUC0xOTkyMWVmNTo0MmJKc2t1aWRCQDA=`,
    });
    const options = { headers };
    return this.http.post(`${environment.otpApi}/api/v1/otp/validar`, data , options);
  }

  generatePdf(form: GeneratePdf) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.userApp),
    });
    const options = { headers };
    //const url= `${this.urlApi}/reversions/report`;
    return this.http.post(`${this.urlDigital}/membership/confirm`, form, options);
  }
}
