import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { PartnerModel } from '../models/partner.model';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  public partner = new PartnerModel();
  private storePartners = new BehaviorSubject<PartnerModel[]>([this.partner]);
  public storePartners$ = this.storePartners.asObservable();

  constructor() { }

  addPartner(partner: PartnerModel) {
    console.log('partner', partner);
    this.storePartners.next([...this.storePartners.value, partner]);
    console.log('partners', this.storePartners.value);
  }

  deletePartner(id?: number) {
    console.log(this.storePartners.value);
    this.storePartners.value.pop();

    this.storePartners.next([
      ...this.storePartners.value
    ]);
    
    console.log(this.storePartners.value);
  }
  

}
