import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private apiUrl: string;
  public userToken: any;
  public visible: boolean;
  public users = [];

  constructor(private http: HttpClient) {
    this.apiUrl = environment.urlCoviUtils;
    this.visible = false;
    this.readToken();
  }

  // Login
  login(usuario: any) {
    this.isAutenticated();

    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      params: null,
      headers,
    };

    return this.http.post(`${this.apiUrl}/login`, usuario, options).pipe(
      map((resp) => {
        this.saveToken(resp["TOKEN"]);
        return resp;
      })
    );
  }

  private saveToken(token: string) {
    this.userToken = token;
    localStorage.setItem("TokenMA", token);
  }

  readToken() {
    if (localStorage.getItem("TokenMA")) {
      this.userToken = localStorage.getItem("TokenMA");
    } else {
      this.userToken = "";
    }
    return this.userToken;
  }

  logout() {
    localStorage.removeItem("TokenMA");
  }

  isAutenticated(): boolean {
    if (
      localStorage.getItem("TokenMA") === undefined ||
      localStorage.getItem("TokenMA") === null
    ) {
      return false;
    } else {
      return this.userToken;
    }
  }

  rolValidate(): any {
    const rol = localStorage.getItem("userMA");

    if (
      localStorage.getItem("userMA") === undefined ||
      localStorage.getItem("userMA") === null
    ) {
      return false;
    } else if (this.users.indexOf(rol) !== -1) {
      return rol;
    }
  }

  logOut() {
    localStorage.removeItem("identityMA");
    localStorage.removeItem("userMA");
    localStorage.removeItem("TokenMA");
    localStorage.removeItem("RolMA");
  }
}
