import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { GeneratePdf, Prospectus } from 'src/app/models/membership.model';
import { OtpBody } from 'src/app/models/otp.model';
import { BackendService } from 'src/app/services/backend.service';
import { ModalService } from '../../services/modal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-pdf',
  templateUrl: './modal-pdf.component.html',
  styleUrls: ['./modal-pdf.component.scss']
})
export class ModalPdfComponent {
  public base64 : string = '';
  public role: string;

  @Input() form: GeneratePdf;
  @Output() generateOtpCode: EventEmitter<string> = new EventEmitter();
  @Output() sendForm64: EventEmitter<string> = new EventEmitter();

  terms: string = '';

  constructor(
    public modal: ModalService,
    private backend: BackendService,
    private spinner: NgxSpinnerService
    ) { 
      
  }

  ngOnInit() {
    //console.log(this.form, 'pdf');
    this.role = this.form.prospectus.role;
    this.callPdf();
  }
  
  ngOnDestroy() {
    this.modal.close();
  }

  get isElectronicSign() {
    return this.terms === 'si';
  }

  accept() {
    this.modal.close();
  }

  acceptElectronicSign() {
    
    this.generateOtpCode.emit();
    this.modal.show("validateOtp");
  }

  close() {
    this.modal.close();
  }

  callPdf() {
      //console.log(this.form);
      this.spinner.show();
      if (this.form.membership){
        this.backend.generatePdf(this.form).subscribe((response: any) => {
            this.base64 = response.message;
            this.sendForm64.emit(this.base64);
            this.spinner.hide();
            //console.log(response,' res pdf');
          
        },(err: any) => {
          this.spinner.hide();
          Swal.fire({ 
            title: 'Error al generar Pdf',   
            type: 'error'
          });
        })
      }
    }
}
