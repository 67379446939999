import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FileData } from '../models/file.model';

@Injectable({
  providedIn: 'root'
})
export class SubjectService {

  public stateStep = new BehaviorSubject<number>(0);
  public stateStep$ = this.stateStep.asObservable();

  public stateUploadFile = new BehaviorSubject<FileData[]>([
    {description: "Cámara de comercio", file: [], descriptionBack: "CAMARACOMERCIO"},
    {description: "RUT", file: [], descriptionBack: "RUT"},
    {description: "Cédula del representante legal", file: [], descriptionBack: "DOCIDENTIDAD"},
    {description: "Certificación bancaria", file: [], descriptionBack: "CERTIFICACION"},
    {description: "Estados financieros del último periodo", file: [], descriptionBack: "ESTADOSFINANCIEROS"},
  ]);  

  public stateValidFiles = new BehaviorSubject<boolean>(false);
  public stateValidFiles$ = this.stateValidFiles.asObservable();

  public stateUploadFile$ = this.stateUploadFile.asObservable();

  constructor() { }

  public changeStateValidFiles(isValidFiles: boolean) {
    this.stateValidFiles.next(isValidFiles);
  }

  public changeStateStep(step: number) {
    this.stateStep.next(step);
  }

  public changeStateUploadFile(fileInfo: FileData, index: number) {

    this.stateUploadFile.value.forEach((fileInfoArray, i) => {
      if(i = index) {
        fileInfoArray = fileInfo
      }
    });
    
    this.stateUploadFile.next(this.stateUploadFile.value);

    console.log("Archivo", this.stateUploadFile);
  }

  public deleteFile(index: number) {
    this.stateUploadFile.value.forEach((fileInfoArray, i) => {
      if(i === index) {
        console.log("i:", i);
        console.log("index:", index);
        
        fileInfoArray.file = []
      }
    });
    this.stateUploadFile.next(this.stateUploadFile.value);

    console.log(this.stateUploadFile);
  }

  public getStateUploadFile(isPartners: boolean) {
    if(isPartners) {
      /**return this.stateUploadFile$.pipe(
        map((fileData: FileData[]) => {
          return fileData.filter( (file: FileData) => file.description !== 'Cámara de comercio')
        })
      )
      */
     return this.stateUploadFile$;
    } else {
      return  this.stateUploadFile$;
    }
   
  }
}
