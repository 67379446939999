import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgxDropzoneImagePreviewComponent } from 'ngx-dropzone';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { FileData } from 'src/app/models/file.model';
import { SubjectService } from 'src/app/services/subject.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {

  @ViewChild("display") display: ElementRef;
  @Output() chargeResult: EventEmitter<File[]> = new EventEmitter();
  @Output() isValidFile: EventEmitter<any> = new EventEmitter();
  @Input() fileInfo: FileData;
  @Input() indexFileList: number;
  /* public files: File[] = []; */
  public mb = 1024 * 1024;
  public maxFileSize = 10 * this.mb;
  public fileSelected: File;
  public isUploadFile: boolean = false;
  public subs1: Subscription;
  public cancel = false;

  constructor(public toastr: ToastrService, private subject: SubjectService) {
  }

  ngOnInit(): void {
    //console.log(this.fileInfo);
    //console.log(this.indexFileList);
  }

  // DropZone
  onSelect(event) {
    this.fileSelected = event.addedFiles[0];
    let selectedFilesSize = 0;
    let selectedAddedFiles = 0;
    if(event.rejectedFiles[0])
    
    if(event.rejectedFiles[0].size > this.maxFileSize) {
      this.toastr.error("El tamaño máximo permitido es de 10 megabytes (MB)", "");
    } else {
      this.toastr.error("Formato no válido", "")
    }

    event.addedFiles.forEach(file => {
      selectedAddedFiles += file.size;
    });


    /* this.files.forEach(file => {
      selectedFilesSize += file.size;
    }); */
    
    if((selectedAddedFiles + selectedFilesSize )>= this.maxFileSize) {
      this.toastr.error(`El tamaño máximo permitido es de 10 megabytes (MB)`, "")
      return;
    }

    if(event.addedFiles.length > 1) {
      this.toastr.error(`Solo se permite subir 1 archivo.`, "")
      return;
    }

    this.fileInfo.file = event.addedFiles;    
    this.uploadFile();
    this.toggleUploadFile();
  }

  toggleUploadFile() {
    this.isUploadFile = !this.isUploadFile;
  }

  onRemove(event, one: boolean) {
   if(one) {
    let file: File[] = [];
    this.fileInfo.file = [];
    this.subject.deleteFile(this.indexFileList);
   } else {
    this.subject.deleteFile(this.indexFileList);
   }
    this.fileSelected = null;
  }

  // DropZone
  uploadFile() {
    this.chargeResult.emit(this.fileInfo.file);
    this.isValidFile.emit({index: this.indexFileList, isFileValid: true});
    
    this.subject.changeStateUploadFile(this.fileInfo, this.indexFileList);
    /* setTimeout(() => {
      console.log("Display", this.display.nativeElement.focus());
    }, 3000) */
    
  }
  
}