import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-modal-electronic-signature',
  templateUrl: './modal-electronic-signature.component.html',
  styleUrls: ['./modal-electronic-signature.component.scss']
})
export class ModalElectronicSignatureComponent implements OnInit {

  constructor(public modal: ModalService) { }

  ngOnInit() {
  }
  
  close() {
    this.modal.close();
  }

}
