import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class ModalService {
  public modal: any = {
    termsAndConditions: false,
    pdf: false
  };

  public payload: any;

  get propModal() {
    return this.modal;
  }

  get payloadModal() {
    return this.payload;
  }

  show(modalName?: string, payload?: any) {
    //console.log(modalName);
    if (!modalName) {
      return;
    }
    this.modal[modalName] = true;
    this.payload = payload;
  }

  close() {
    for (const property in this.modal) {
      if (property) {
        this.modal[property] = false;
      }
    }
  }
}