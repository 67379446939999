import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: '[appOnlyNumber]'
})
export class OnlyNumberDirective implements OnInit {

  public arrayCodes = [8, 9, 37, 39];
  constructor( private el: ElementRef) {}

  ngOnInit() {

  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // console.log('key press');

    if (this.arrayCodes.indexOf(event.keyCode) === -1 && event.key.search(/\d\b/) === -1) {
      event.preventDefault();
  }

}

}
