import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-modal-consultation-policy',
  templateUrl: './modal-consultation-policy.component.html',
  styleUrls: ['./modal-consultation-policy.component.scss']
})
export class ModalConsultationPolicyComponent implements OnInit {

  constructor(public modal: ModalService) { }

  ngOnInit() {
  }

  close() {
    this.modal.close();
  }

}
