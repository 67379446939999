import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { OtpRequestModel } from '../pages/registro/models/request/otp.request.model';
import { OtpResponseModel } from '../pages/registro/models/response/otp.response.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  public urlApi: string = '';
  public headers: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient) {
    this.urlApi = environment.urlDigital;
  }

  signOtp(otpRequest: OtpRequestModel): Observable<OtpResponseModel> {

    const options = {
      headers: this.headers,
    }

    return this.http.post<OtpResponseModel>(`${this.urlApi}/api/auth/sign-otp`, otpRequest, options);
  }


}
